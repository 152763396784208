const Init = (() => {

    let website = {},
        score = {},
        loader = {},
        scroll = {},
        menu = {},
        mouse = {},
        wheel = {},
        touch = {},
        pjax = {},
        mv = {},
        tab = {},
        footer = {},
        panelHover = {},
        fixScroll = {},
        contactForm = {},
        modal = {},
        vImg = {};

    website.body = document.body;
    website.btnMenu = $('#btn-m');
    website.menu = $('#menu');
    website.panelWrap = $('#panel-wrap');
    website.panel = '#panel';
    website.url = document.URL;
    website.winW = window.innerWidth;
    website.winH = window.innerHeight;
    website.breakPoint = 768;
    website.isDesktop = true;
    website.isFirst = true;
    website.animationFrame = null;

    if (website.breakPoint >= website.winW) {
        website.isDesktop = false;
    }

    const Modules = {

        init() {
            footer.sand = document.getElementById('sand');
            footer.plant = document.getElementById('plant');
        },

        score() {

            score = {
                top: $('.page-top').length ? true : false,
                gd: $('.page-green').length ? true : false,
                gdDetail: $('.page-green-detail').length ? true : false,
                kp: $('.page-keep').length ? true : false,
                contact: $('.page-contact').length ? true : false
            };

            $('.link').removeClass('no-barba');
            // website.body.classList.add('scroll-hide');

            if (score.top) {
                $('.l-top').addClass('no-barba');
            }

            $('.link.no-barba').off('click');
            $('.link.no-barba').on('click', function(e) {
                e.preventDefault();
                // Menu.close();
            });
        },

        tweenX(target, targetX) {
            TweenLite.to(target, 1.5, {
                x: targetX,
                z: 0,
                ease: 'none'
            });
        },

        tweenY(target, targetY) {
            TweenLite.to(target, 1.5, {
                y: targetY,
                z: 0,
                ease: 'none'
            });
        },

        forSafari() {

            if (!$('picture').length && !$('.is-safari').length) { return };

            $('picture').each(function(i) {

                let that = $(this),
                    sourcePC = that.find('source').eq(0),
                    sourceSP = that.find('source').eq(1),
                    srcsetPC = sourcePC.attr('srcset'),
                    srcsetSP = sourceSP.attr('srcset'),
                    img = that.find('img'),
                    src = img.attr('src');

                if (typeof srcsetPC === 'undefined') {
                    srcsetPC = sourcePC.attr('data-srcset');
                    srcsetSP = sourceSP.attr('data-srcset');
                    src = img.attr('data-src');

                    sourcePC.attr('data-srcset', srcsetPC.replace('1x.webp', '1x.jpg').replace('2x.webp', '2x.jpg'));
                    sourceSP.attr('data-srcset', srcsetSP.replace('sp.webp', 'sp.jpg'));
                    img.attr('data-src', src.replace('.webp', '.jpg'));
                } else {
                    sourcePC.attr('srcset', srcsetPC.replace('1x.webp', '1x.jpg').replace('2x.webp', '2x.jpg'));
                    sourceSP.attr('srcset', srcsetSP.replace('sp.webp', 'sp.jpg'));
                    img.attr('src', src.replace('.webp', '.jpg'));
                }

            });
        },

        parallaxFooter() {
            let rect1 = footer.sand.getBoundingClientRect(),
                rect2 = footer.plant.getBoundingClientRect();

            if (0 >= rect1.top - website.winH) {
                TweenMax.set(footer.sand, {
                    y: (rect1.height - rect1.top) * 0.1,
                    z: 0
                });
            }

            if (0 >= rect2.top - website.winH) {
                TweenMax.set(footer.plant, {
                    y: (rect2.height - rect2.top - website.winH * 1.2) * 0.1,
                    z: 0
                });
            }
        }
    }


    const Resize = {

        init() {
            Resize.set();
            Resize.update();

            console.log('!-- resize init --!');
        },

        set() {
            website.winW = window.innerWidth;
            website.winH = window.innerHeight;

            if (score.top) {
                Top.resize();
            }

            this.txtSlice();
        },

        update() {

            $(window).off('resize');
            $(window).on('resize', function() {
                website.winW = window.innerWidth;
                website.winH = window.innerHeight;

                if (website.breakPoint > website.winW) {
                    if (website.isDesktop) {
                        website.isDesktop = false;
                        location.reload();

                        console.log('!-- resize init --!');
                    }
                } else {
                    if (!website.isDesktop) {
                        website.isDesktop = true;
                        location.reload();

                        console.log('!-- resize init --!');
                    }
                }

                Resize.set();
            });
        },

        txtSlice() {

            if (!website.isDesktop) { return };

            let target = $('.t-slice .t');

            for (let i = 0; i < target.length; i++) {
                let w = target.eq(i).width(),
                    h = target.eq(i).height();

                if (h > 40) {
                    let txt = target.eq(i).text(),
                        end = Math.floor(w / 18.5),
                        result = txt.slice(0, end);

                    target.eq(i).text(result + '...');
                }
            }
        },

        spReset() {
            if (!website.isDesktop) {

                website.body.style.height = website.winH + 'px';

                $('.parallax-b').attr('style', '');
            } else {
                $('body').attr('style', '');
            }
        },

        slideImgCenter() {
            website.sizeFit = $('.js-slide-fit');

            // if (website.imgRatio > website.winW / website.winH) {
            //     website.sizeFit.css({
            //         'position': 'absolute',
            //         'width': Math.floor(website.winH * website.imgRatio),
            //         'height': website.winH,
            //         'top': 0,
            //         'left': Math.floor((website.winW - website.winH * website.imgRatio) / 2)
            //     });
            // } else {
            //     website.sizeFit.css({
            //         'position': 'absolute',
            //         'width': website.winW,
            //         'height': Math.floor(website.winW / website.imgRatio),
            //         'top': Math.floor((website.winH - website.winW / website.imgRatio) / 2),
            //         'left': 0
            //     });
            // }
            // if (website.videoRatio > website.winW / website.winH) {
            //     website.sizeFitVideo.css({
            //         'position': 'absolute',
            //         'width': Math.floor(website.winH * website.videoRatio),
            //         'height': website.winH,
            //         'top': 0,
            //         'left': Math.floor((website.winW - website.winH * website.videoRatio) / 2)
            //     });
            // } else {
            //     website.sizeFitVideo.css({
            //         'position': 'absolute',
            //         'width': website.winW,
            //         'height': Math.floor(website.winW / website.videoRatio),
            //         'top': Math.floor((website.winH - website.winW / website.videoRatio) / 2),
            //         'left': 0
            //     });
            // }
        }
    }


    const SmoothScroll = {

        init() {

            scroll = {
                target: document.getElementById('scroll'),
                delta: 0,
                lastY: 0,
                velocity: 0.08,
                targetY: 0,
                translateY: 0,
                currentY: 0,
                secH: 0,
                parallaxImg: document.querySelectorAll('.parallax-img'),
                parallaxBox: document.querySelectorAll('.parallax-box')
            };

            SmoothScroll.set();
            SmoothScroll.observer();

            console.log('!-- scroll init --!');
        },

        set() {
            if (website.isDesktop) {
                $(window).on('scroll', (e) => {
                    scroll.targetY = $(e.currentTarget).scrollTop();
                });
            } else {
                $('body').css({
                    height: website.winH
                });
                $('#scroll').attr('style', '').off('scroll');

                scroll.targetY = 0;
                scroll.translateY = 0;
                scroll.currentY = 0;

                $('#scroll').on('scroll', function(e) {
                    touch.startY = $('#scroll-wrap').offset().top;
                    scroll.targetY = $('#scroll-wrap').offset().top;
                    // console.log(touch.startY);

                    if (score.top) {
                        Top.pictureSP();
                    }
                    // if (Math.abs(touch.startY) >= 100) {
                    //     website.body.classList.add('is-scrolled');
                    // } else {
                    //     website.body.classList.remove('is-scrolled');
                    // }
                });
            }
        },

        update() {

            scroll.currentY = SmoothScroll.easing(scroll.currentY, scroll.targetY, scroll.velocity);
            scroll.secH = scroll.target.getBoundingClientRect().height - 1;

            document.body.style.height = Math.floor(scroll.secH) + 'px';

            TweenLite.set(scroll.target, {
                y: -(scroll.currentY),
                z: 0
            });

        },

        easing(start, end, multiplier) {
            return (1 - multiplier) * start + multiplier * end;
        },

        observer() {

            let targets = Array.from(document.querySelectorAll('.scroll-in')),
                options = {
                    rootMargin: '0px 0px'
                };

            let observer = new IntersectionObserver(SmoothScroll.observerCallback, options);

            targets.forEach((target) => {
                observer.observe(target);
            });
        },

        observerCallback(entries, object) {
            entries.forEach(function(entry, i) {
                if (!entry.isIntersecting) return;
                entry.target.classList.add('is-in');
                object.unobserve(entry.target);
            });
        },

        parallaxB() {

            for (let i = 0; i < scroll.parallaxB.length; i++) {
                let objRect = scroll.parallaxB[i].getBoundingClientRect(),
                    scale = scroll.parallaxB[i].getAttribute('data-scale');

                if (website.winH > objRect.top) {
                    if (objRect.top + objRect.height > 0) {
                        let y = Math.floor((-objRect.top + website.winH) * scale);
                        SmoothScroll.tweenY(scroll.parallaxB[i], y);
                    }
                } else {
                    SmoothScroll.tweenY(scroll.parallaxB[i], 0);
                }
            }
        },

        parallaxT() {

            for (let i = 0; i < scroll.parallaxT.length; i++) {

                let objRect = scroll.parallaxT[i].getBoundingClientRect(),
                    scale = scroll.parallaxT[i].getAttribute('data-scale'),
                    transform;

                if (website.winH > objRect.top) {
                    if (objRect.top + objRect.height > 0) {
                        let y = Math.floor((objRect.top - website.winH) * scale);
                        SmoothScroll.tweenY(scroll.parallaxT[i], y);
                    }
                } else {
                    SmoothScroll.tweenY(scroll.parallaxT[i], 0);
                }

            }
        }
    }

    const Modal = {
        init() {
            modal.cont = $('#modal-cont');
            modal.wrap = $('.modal-wrap');
            modal.btnClose = $('#modal-btn-close');
            modal.trigger = $('.modal-trigger');
            Modal.set();
        },

        set() {
            modal.trigger.off('click');
            modal.btnClose.off('click');

            modal.trigger.on('click', function() {
                let i = $(this).attr('data-num');
                if (website.isDesktop) {
                    document.body.classList.add('hidden');
                }
                modal.cont.addClass('is-in');
                modal.wrap.eq(i).addClass('is-in');
            });

            modal.btnClose.on('click', function() {
                if (website.isDesktop) {
                    document.body.classList.remove('hidden');
                }
                modal.cont.removeClass('is-in');
                modal.wrap.removeClass('is-in');

                if (website.isDesktop) {
                    modal.wrap.find('.inner').animate({
                        scrollTop: 0
                    }, 1000);
                } else {
                    let t = 0,
                        pos = { to: t },
                        box = $('.modal-wrap'),
                        dist = t - box.position().top;

                    box.stop().animate({
                        scrollTop: 0
                    }, 1000);
                }
            });
        }
    }

    const Underline = {

        init() {

            underline.line = $('.u');
            underline.target = '.u-target';

            Underline.set();
        },

        set() {

            underline.line.off('mouseenter mouseleave');
            if (!website.isDesktop) { return };

            underline.line.on('mouseenter mouseleave', function(e) {
                let target = $(this).find(underline.target);
                if (e.type === 'mouseenter') {
                    TweenLite.fromTo(target, .4, {
                        x: '-100%',
                        z: 0
                    }, {
                        x: '0%',
                        z: 0
                    });
                } else {
                    TweenLite.to(target, .4, {
                        x: '100%',
                        z: 0
                    });
                }
            });
        }
    }

    const Mouse = {

        init() {

            mouse.cursor = document.getElementById('cursor');
            // mouse.bird = document.getElementById('bird');
            // mouse.birdImg = document.getElementById('bird-img');
            mouse.x = 0;
            mouse.y = 0;
            mouse.tx = 0;
            mouse.ty = 0;
            mouse.dx = 0;
            mouse.dy = 0;
            mouse.ease = 0.06;
            mouse.hoverTarget = null;
            mouse.isHover = false;
            mouse.dot = {
                x: 0,
                y: 0
            };
        },

        set() {

            $(window).off('mousemove');
            if (!website.isDesktop) { return }

            $(window).on('mousemove', function(e) {
                mouse.x = e.clientX;
                mouse.y = e.clientY;
            });

        },

        append() {
            if ($('.pagenation').length && $('.wp-pagenavi').length) {
                $('.wp-pagenavi a').addClass('cursor-in').attr('data-type', 'arw').append('<span class="cursor-pos"></span><i class="arw"></i>');
            }
        },

        guide() {

            this.append();

            $('.cursor-in').off('mouseleave mousemove');
            $('.cursor-in').on('mouseleave mouseenter', function(e) {

                let that = $(this);
                mouse.hoverTarget = $(this).find('.cursor-pos');

                if (e.type === 'mouseenter') {
                    mouse.isHover = true;
                    // mouse.bird.classList.add('is-hide');

                    if (that.attr('data-type') === 'basic') {
                        mouse.cursor.classList.add('is-basic');
                    } else if (that.attr('data-type') === 'menu') {
                        mouse.cursor.classList.add('is-menu');
                    } else if (that.attr('data-type') === 'arw') {
                        mouse.cursor.classList.add('is-arw');
                    } else if (that.attr('data-type') === 'arw-w') {
                        mouse.cursor.classList.add('is-arw-w');
                    } else if (that.attr('data-type') === 'contact-btn') {
                        mouse.cursor.classList.add('is-contact-btn');
                    } else if (that.attr('data-type') === 'plus') {
                        mouse.cursor.classList.add('is-plus');
                    } else if (that.attr('data-type') === 'contact-w') {
                        mouse.cursor.classList.add('is-contact-w');
                    } else if (that.attr('data-type') === 'sns') {
                        mouse.cursor.classList.add('is-sns');
                    } else if (that.attr('data-type') === 'sns-g') {
                        mouse.cursor.classList.add('is-sns-g');
                    }

                } else {
                    mouse.isHover = false;
                    // mouse.bird.classList.remove('is-hide');
                    mouse.cursor.classList.remove('is-basic');
                    mouse.cursor.classList.remove('is-menu');
                    mouse.cursor.classList.remove('is-arw');
                    mouse.cursor.classList.remove('is-contact-btn');
                    mouse.cursor.classList.remove('is-plus');
                    mouse.cursor.classList.remove('is-arw-w');
                    mouse.cursor.classList.remove('is-contact-w');
                    mouse.cursor.classList.remove('is-sns');
                    mouse.cursor.classList.remove('is-sns-g');
                }
            });
        },

        update() {

            if (mouse.isHover) {

                let w = mouse.hoverTarget.width(),
                    h = mouse.hoverTarget.height(),
                    t = mouse.hoverTarget.offset().top - scroll.targetY + w / 2,
                    l = mouse.hoverTarget.offset().left + h / 2;

                TweenLite.to(mouse.cursor, .8, {
                    x: l,
                    y: t,
                    z: 0
                });

            } else {
                TweenLite.to(mouse.cursor, .8, {
                    x: Math.floor(mouse.x),
                    y: Math.floor(mouse.y),
                    z: 0
                });
            }


            // if (-40 >= mouse.x - mouse.dot.x) {
            //     mouse.dot.x += (mouse.x - mouse.dot.x) * mouse.ease;
            //     mouse.tx = mouse.dot.x;
            // } else if (mouse.x - mouse.dot.x >= 40) {
            //     mouse.dot.x += (mouse.x - mouse.dot.x) * mouse.ease;
            //     mouse.tx = mouse.dot.x;
            // }

            // if (-40 >= mouse.y - mouse.dot.y) {
            //     mouse.dot.y += (mouse.y - mouse.dot.y) * mouse.ease;
            //     mouse.ty = mouse.dot.y;
            // } else if (mouse.y - mouse.dot.y >= 40) {
            //     mouse.dot.y += (mouse.y - mouse.dot.y) * mouse.ease;
            //     mouse.ty = mouse.dot.y;
            // }

            // let rad = Math.atan2(mouse.y - mouse.dot.y, mouse.x - mouse.dot.x),
            //     degree = rad * 180 / Math.PI;

            // TweenMax.to(mouse.bird, 0.8, {
            //     x: mouse.tx,
            //     y: mouse.ty,
            //     z: 0
            // });

            // TweenMax.set(mouse.birdImg, {
            //     z: 0,
            //     rotation: degree,
            //     rotationX: degree,
            //     rotationY: 0
            // });
        }
    }

    const Filter = {

        init() {

            if (pjax.filterBool === false) {
                setTimeout(() => {
                    $('#isotope').isotope({
                        itemSelector: '.sort-cell',
                        filter: '.sort-cell',
                        percentPosition: true,
                        queue: false
                    });
                }, 1000);
            } else {
                pjax.filter = undefined;
                pjax.filterBool = false;
            }

            tab.trigger = $('.tab');
            tab.len = $('.sort-cell').length;
            this.set();
            this.tab();
        },

        set() {

            const call = (that) => {


                if (!website.isDesktop) {
                    $('.sort').css('opacity', 0);
                    // setTimeout(() => {
                    tab.trigger.removeClass('is-open');
                    tab.trigger.find('.hide-box').animate({
                        height: 0
                    }, 600);

                    $('.tab').find('.current').text(that.text());

                    // }, 600);
                }

                $('#isotope').isotope({
                    filter: that.attr('data-filter'),
                    animationOptions: {
                        duration: 2000,
                        easing: 'linear',
                        queue: false
                    }
                });
            };

            $('.sort').on('click tap', function() {

                let that = $(this),
                    i = that.index();

                $('.sort.is-current').removeClass('is-current');

                if (website.menu.hasClass('is-in')) {

                    $('#btn-m').removeClass('is-hide');
                    website.body.classList.remove('hidden');
                    website.panelWrap.removeClass('is-w');
                    website.menu.removeClass('is-in');
                    Menu.close();
                }

                if (that.hasClass('all')) {
                    $('.all').addClass('is-current');
                } else {
                    $('.sort-wrap').each(function() {
                        let tgt1 = $(this).find('.head').find('.sort').eq(i + 1),
                            tgt2 = $(this).find('.tab').find('.sort').eq(i);

                        tgt1.addClass('is-current');
                        tgt2.addClass('is-current');
                    });
                }

                if (website.isDesktop) {
                    $('html,body').animate({
                        scrollTop: 0
                    }, {
                        duration: 100,
                        complete: () => {
                            setTimeout(() => {
                                call(that);
                            }, 400);
                        }
                    });
                } else {
                    $('#scroll').animate({
                        scrollTop: 0
                    }, {
                        duration: 600,
                        complete: () => {
                            setTimeout(() => {
                                call(that);
                            }, 400);
                        }
                    });
                }
            });
        },

        tab() {

            tab.trigger.off('click');
            tab.trigger.on('click', function() {

                let that = $(this),
                    current = that.find('.current'),
                    sort = that.find('.sort'),
                    h = that.find('.hide-box .in').outerHeight(true) + 100;

                if (!that.hasClass('is-open')) {
                    that.addClass('is-open');
                    that.find('.hide-box').animate({
                        height: h
                    }, 600, () => {
                        sort.css('opacity', 1);
                    });
                } else {
                    sort.css('opacity', 0);
                    setTimeout(() => {
                        that.removeClass('is-open');
                        that.find('.hide-box').animate({
                            height: 0
                        }, 600);
                    }, 600);
                }
            });
        }
    }

    const Pjax = {

        init() {

            pjax.href = null;
            pjax.cont = null;
            pjax.popstate = false;
            pjax.lastElementClicked = null;
            pjax.filter = undefined;
            pjax.filterBool = false;
            pjax.filterIndex = 0;
            pjax.nameSpace = null;

            Barba.Pjax.init();
            Barba.Prefetch.init();

            this.set();
        },

        set() {

            let pjaxGreen = Barba.BaseView.extend({
                namespace: 'page-green',
                onEnter: () => {},
                onEnterCompleted: () => {

                    if (pjax.filterBool) {
                        $('#isotope').isotope({
                            filter: pjax.filter
                        });

                        $('.sort.is-current').removeClass('is-current');
                        $('.sort-wrap').each(function() {
                            let tgt1 = $(this).find('.head').find('.sort').eq(pjax.filterIndex + 1),
                                tgt2 = $(this).find('.tab').find('.sort').eq(pjax.filterIndex);

                            tgt1.addClass('is-current');
                            tgt2.addClass('is-current');
                        });
                    }
                },
                onLeave: () => {},
                onLeaveCompleted: () => {}
            });

            pjaxGreen.init();

            Barba.Dispatcher.on('linkClicked', function(t) {

                pjax.href = $(t).attr('href');
                pjax.filter = $(t).attr('data-filter');
                pjax.filterIndex = $(t).index();
                pjax.cont = '#pjax-container';
                pjax.lastElementClicked = t;

                cancelAnimationFrame(website.animationFrame);

                if (pjax.filter !== undefined) {
                    pjax.filterBool = true;
                } else {
                    pjax.filter = undefined;
                    pjax.filterBool = false;
                }

                if (website.menu.hasClass('is-in')) {

                    $('#btn-m').removeClass('is-hide');
                    website.body.classList.remove('hidden');
                    website.panelWrap.removeClass('is-w');
                    website.menu.removeClass('is-in');
                    Menu.close();
                }

                let e = $(t);
            });

            Barba.Dispatcher.on('initStateChange', function() {});

            Barba.Dispatcher.on('initStateChange', function() {
                if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
                    ga('send', 'pageview', location.pathname);
                }
                if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) {
                    gtag('config', 'UA-160046653-1', { 'page_path': location.pathname });
                }
            });
            Barba.Dispatcher.on('transitionCompleted', function() {
                Modules.score();
                App.set();
            });

            const barbaTransition = Barba.BaseTransition.extend({

                start: function() {
                    Promise.all([this.newContainerLoading, this.ready(this.newContainer)]).then(this.fadeIn.bind(this));
                },

                ready: function(newCont) {
                    let deferred = Barba.Utils.deferred();
                    return animation.ready(deferred), deferred.promise;
                },
                fadeIn: function() {
                    let t = this,
                        oldCont = $(this.oldContainer),
                        newCont = $(this.newContainer);
                    animation.fadeIn(oldCont, newCont, t);
                }
            });

            let animation = {

                ready: function(deferred) {
                    deferred.resolve();
                },

                fadeIn: function(oldCont, newCont, t) {

                    let cb = () => {
                        setTimeout(() => {

                            $('html,body').animate({
                                scrollTop: 0
                            }, 10);

                            $('body').attr('style', '');

                            oldCont.hide();
                            t.done();

                            this.fadeOut();

                        }, 1000);
                    };

                    Loader.in(cb());
                },

                fadeOut: function() {

                    mouse.cursor.classList.remove('is-basic');
                    mouse.cursor.classList.remove('is-menu');
                    mouse.cursor.classList.remove('is-arw');
                    mouse.cursor.classList.remove('is-contact-btn');
                    mouse.cursor.classList.remove('is-plus');
                    mouse.cursor.classList.remove('is-arw-w');
                    mouse.cursor.classList.remove('is-contact-w');
                    mouse.cursor.classList.remove('is-sns');
                    mouse.cursor.classList.remove('is-sns-g');

                    Loader.out();
                }
            }

            Barba.Pjax.getTransition = function() {
                return barbaTransition;
            };
        }
    }

    const Menu = {

        init() {

            let that = this;
            $('#btn-m').on('click', function() {
                $(this).addClass('is-hide');
                website.body.classList.add('hidden');
                website.panelWrap.addClass('is-w');
                website.menu.addClass('is-in');
                that.open();
            });

            $('#close').on('click', function() {
                $('#btn-m').removeClass('is-hide');
                website.body.classList.remove('hidden');
                website.panelWrap.removeClass('is-w');
                website.menu.removeClass('is-in');
                that.close();
            });
        },

        open() {

            let x = website.isDesktop ? [-website.winW, -(website.winW - 360)] : [-website.winW, -(website.winW - 250)];

            let panelIn = anime({
                targets: website.panel,
                translateX: x,
                translateY: 0,
                translateZ: 0,
                easing: [.47, 0, .22, 1],
                duration: 1000
            });

            Snap('#p-wave-r').animate({
                d: 'M83,0H0v800h83C83,600,0.1,599.7,0.1,400S83,200,83,0z'
            }, 1000, mina.easeinout);
        },

        close() {

            let x = website.isDesktop ? [-(website.winW - 360), -website.winW] : [-(website.winW - 250), -website.winW];

            let panelOut = anime({
                targets: website.panel,
                translateX: x,
                translateY: 0,
                translateZ: 0,
                easing: [.47, 0, .22, 1],
                duration: 1000
            });

            Snap('#p-wave-r').animate({
                d: 'M0,0L0,0v800l0,0c0-200,0.1-200.3,0.1-400S0,200,0,0z'
            }, 1000, mina.easeinout);
        }
    }

    const Anchor = {

        init() {
            $('.anchor').on('click', function(e) {
                (e.preventDefault) ? e.preventDefault(): e.returnValue = false;
                let tgt = $(this).attr('data-target'),
                    t = tgt === '#' ? 0 : $(tgt).offset().top,
                    pos = { to: t },
                    box = $('#scroll'),
                    dist = t - box.position().top;

                if (website.isDesktop) {
                    $('html,body').animate({
                        scrollTop: t
                    }, 800);
                } else {
                    box.stop().animate({
                        scrollTop: box.scrollTop() + dist
                    }, 800);
                }
            });
        }
    }

    const PanelHover = {

        init() {

            panelHover.target = $('.sort-cell');
            panelHover.panel = document.querySelectorAll('.h-panel');
            panelHover.waveT = $('.w-t');
            panelHover.waveB = $('.w-b');

            for (let i = 0; i < panelHover.waveT.length; i++) {
                panelHover.waveT.eq(i).addClass('w-t-' + i);
                panelHover.waveB.eq(i).addClass('w-b-' + i);
            }

            this.set();
        },

        set() {

            let animIn = null,
                animOut = null,
                dur = 1000;

            panelHover.target.on('mouseenter mouseleave', function(e) {

                let n = $(this).index();

                if (e.type === 'mouseenter') {

                    panelHover.waveT.eq(n).attr('d', 'M0,80.2c62,0,100.8,0,203.9,0c114.9,0,149.4,0,191.1,0V82H0V80.2z');

                    animIn = anime({
                        targets: panelHover.panel[n],
                        translateY: ['101%', '60%'],
                        duration: dur,
                        easing: [.05, .71, .37, 1]
                    });

                    Snap('.w-t-' + n).animate({
                        d: 'M0,72.7C193.8,79.1,225.7,38.5,302.2,28c63.6-8.7,84.1,10.5,92.8,16.9V82H0V72.7z'
                    }, 200, mina.easeOut, () => {
                        Snap('.w-t-' + n).animate({
                            d: 'M0,19.7c52.2-25.5,109.3-16.1,158.1,6C250.4,67.7,314.6,84.7,395,55v27H0V19.7z'
                        }, 800, mina.easeOut);
                    });

                } else {

                    animIn.pause();
                    animOut = anime({
                        targets: panelHover.panel[n],
                        translateY: '135%',
                        duration: dur / 2,
                        easing: [.47, 0, .22, 1]
                    });

                    Snap('.w-t-' + n).animate({
                        d: 'M0,80.2c62,0,100.8,0,203.9,0c114.9,0,149.4,0,191.1,0V82H0V80.2z'
                    }, dur / 2, mina.easeinout, () => {});
                }
            });
        }
    }

    const FixScroll = {

        init() {
            fixScroll.target = document.getElementById('fix-box');
            fixScroll.former = document.getElementById('former');
        },

        update() {

            let targetRect = fixScroll.target.getBoundingClientRect(),
                formerRect = fixScroll.former.getBoundingClientRect(),
                targetH = targetRect.height,
                formerH = formerRect.height,
                marginTop = 165 + 18 + 17;

            if (scroll.currentY + targetH - 90 > formerH) {

                TweenLite.set(fixScroll.target, {
                    y: formerH + marginTop - targetH - scroll.currentY,
                    z: 0
                });
            } else {

                if (200 - scroll.currentY > 110) {
                    TweenLite.set(fixScroll.target, {
                        y: 200 - scroll.currentY,
                        z: 0
                    });
                } else {
                    TweenLite.set(fixScroll.target, {
                        y: 110,
                        z: 0
                    });
                }
            }
        }
    }

    const Contactform = {

        init() {
            contactForm.require = $('.require');
            contactForm.confirm = $('#confirm');

            Contactform.set();
        },

        set() {

            $('.btn-wrap').next('p').remove();
            $('#confirm').next('p').remove();

            let isFirst = true;

            // $('form').attr('onsubmit','return false;');

            contactForm.require.blur(function() { // 空の場合
                let i = $(this).parents('.require-wrap').index();
                isFirst = false;
                if ($(this).val() === '') {
                    $(this).parents('.require-wrap').addClass('is-error');
                } else {
                    $(this).parents('.require-wrap').removeClass('is-error');

                }
            });

            $('textarea').blur(function() {
                isFirst = false;
                if ($(this).val() === '') {
                    $(this).parents('.require-wrap').addClass('is-error');
                } else {
                    $(this).parents('.require-wrap').removeClass('is-error');

                }
            });

            contactForm.confirm.on('click', function(e) {

                if ($('.is-error').length || isFirst) {
                    e.preventDefault();

                    if (website.isDesktop) {
                        $('html,body').animate({
                            scrollTop: 640
                        }, 600);
                    } else {
                        $('#scroll').stop().animate({
                            scrollTop: 300
                        }, 600);
                    }

                    contactForm.require.each(function(i) {
                        if (contactForm.require.eq(i).val() === '') {
                            contactForm.require.eq(i).parents('.require-wrap').addClass('is-error');
                        }
                    });

                    // $('form').attr('onsubmit','return false;');
                }
            });
        }
    };

    const Loader = {

        init() {
            loader.waveT = '#l-t';
            loader.waveB = '#l-b';
            loader.prog = $('#prog');
            loader.circ = $('#c-loader');
            loader.txt = $('#prog-t');
            loader.pi = website.isDesktop ? 244 : 244;

            setTimeout(() => {
                this.opening();
            }, 600);
        },

        opening() {

            const updateProgress = () => {

                loader.target = (loader.loaded / loader.total) * 100;
                loader.current += (loader.target - loader.current) * 0.1;
                loader.palam = loader.pi * (loader.current * 0.01);

                loader.circ.css({ 'stroke-dashoffset': (loader.pi - loader.palam) + 'px' });
                loader.txt.text(Math.floor(loader.current));

                if (loader.current >= 100) {
                    clearInterval(loader.timer);
                    setTimeout(() => {

                        loader.prog.addClass('is-complete');
                        $('#l-b').attr('d', 'M0-0.5V7c239.7,0,240,99.5,480,99.5S720.3,7,960,7s240,99.5,480,99.5v-107H0z');

                        setTimeout(() => {
                            let outAnim = anime({
                                targets: '#load',
                                translateY: ['0%', '-100%'],
                                translateZ: 0,
                                duration: 1400,
                                easing: [.47, 0, .22, 1],
                                begin: () => {
                                    loader.prog.addClass('is-hide');
                                    $('.pjax-wrap').removeClass('first-hide');
                                }
                            });

                            Snap('#l-b').animate({
                                d: 'M0,0L0,0c239.7,0,240,0,480,0s240.3,0,480,0s240,0,480,0l0,0H0z'
                            }, 1400, mina.easeinout);
                        }, 1600);
                    }, 600);
                }

                if (loader.current > 99.9) {
                    loader.current = 100;
                }
            }

            loader.imgLoad = imagesLoaded('body');
            loader.total = loader.imgLoad.images.length;
            loader.loaded = 0;
            loader.current = 0;
            loader.timer = setInterval(updateProgress, 1000 / 60);

            loader.imgLoad.on('progress', function() {
                loader.loaded++;
            });
        },

        in (cb) {

            $('#l-t').attr('d', 'M1440,106.5v-107C1200-0.5,1199.7,99,960,99S720-0.5,480-0.5S239.7,99,0,99v7.5H1440z');
            // loader.prog.addClass('is-in');
            let InAnim = anime({
                targets: '#load',
                translateY: ['120%', '0%'],
                translateZ: 0,
                duration: 1000,
                easing: [.47, 0, .22, 1],
                complete: () => {
                    return cb;
                }
            });

            Snap('#l-t').animate({
                d: 'M1440,106.5L1440,106.5c-240,0-240.3,0-480,0s-240,0-480,0s-240.3,0-480,0l0,0H1440z'
            }, 1000, mina.easeinout);
        },

        out() {

            $('#l-b').attr('d', 'M0-0.5V7c239.7,0,240,99.5,480,99.5S720.3,7,960,7s240,99.5,480,99.5v-107H0z');

            $('body').imagesLoaded(() => {

                setTimeout(() => {
                    // loader.prog.removeClass('is-in');
                    $('.pjax-wrap').removeClass('first-hide');

                    let InAnim = anime({
                        targets: '#load',
                        translateY: ['0%', '-100%'],
                        translateZ: 0,
                        duration: 1000,
                        easing: [.47, 0, .22, 1],
                        complete: () => {}
                    });

                    Snap('#l-b').animate({
                        d: 'M0,0L0,0c239.7,0,240,0,480,0s240.3,0,480,0s240,0,480,0l0,0H0z'
                    }, 1000, mina.easeinout);
                }, 1000);
            });
        }
    }

    const Top = {

        init() {
            mv.l1 = document.getElementById('mv-l1');
            mv.l2 = document.getElementById('mv-l2');
            mv.l3 = document.getElementById('mv-l3');
            mv.l4 = document.getElementById('mv-l4');
            mv.l5 = document.getElementById('mv-l5');

            vImg.wrap = document.getElementById('s2');
            vImg.target = document.getElementById('v-img');

            vImg.layer1 = document.getElementById('v-layer1');
            vImg.layer2 = document.getElementById('v-layer2');
            vImg.layer3 = document.getElementById('v-layer3');
            vImg.layer4 = document.getElementById('v-layer4');
            vImg.layer5 = document.getElementById('v-layer5');
            vImg.layer6 = document.getElementById('v-layer6');
            vImg.layer7 = document.getElementById('v-layer7');
            vImg.layer8 = document.getElementById('v-layer8');
            vImg.layer9 = document.getElementById('v-layer9');
            vImg.layer10 = document.getElementById('v-layer10');
            vImg.layer0 = document.getElementById('v-layer0');


            vImg.wrapRect = vImg.wrap.getBoundingClientRect();
            vImg.targetRect = vImg.target.getBoundingClientRect();
            vImg.ratio = website.isDesktop ? 5960 / 2106 : 1950 / 1255;

            if (website.isDesktop) {
                Top.updates();
            }
        },

        resize() {

        },

        mv() {
            TweenLite.set(mv.l1, {
                x: 0,
                y: scroll.currentY * 0.3,
                z: 0
            });
            TweenLite.set(mv.l2, {
                x: 0,
                y: scroll.currentY * 0.1,
                z: 0
            });
            TweenLite.set(mv.l3, {
                x: 0,
                y: -(scroll.currentY * 0.3),
                z: 0
            });
            TweenLite.set(mv.l4, {
                x: 0,
                y: -(scroll.currentY * 1.5),
                z: 0,
                rotation: scroll.currentY * 0.04
            });

            TweenLite.set(mv.l5, {
                x: 0,
                y: -(scroll.currentY * 0.9),
                z: 0,
                rotation: -(scroll.currentY * 0.03)
            });
        },

        picturePC() {

            vImg.w = website.winH * vImg.ratio;
            vImg.h = website.winH + (vImg.w - website.winW) * 1.4;

            vImg.target.style.width = vImg.w + 'px';
            vImg.wrap.style.height = vImg.h + 'px';

            if (Math.abs(scroll.currentY) >= vImg.wrapRect.top) {

                let x = (vImg.wrapRect.top - Math.abs(scroll.currentY)) * 0.8;

                TweenLite.set(vImg.target, {
                    width: vImg.w,
                    x: x,
                    y: 0,
                    z: 0
                });

                if (Math.abs(x) >= vImg.w - website.winW) {

                    TweenLite.set(vImg.target, {
                        width: vImg.w,
                        x: website.winW - vImg.w + 1,
                        y: 0,
                        z: 0
                    });
                } else {
                    Top.vImgParallax();
                }

            } else {

                TweenLite.set(vImg.target, {
                    width: vImg.w,
                    x: 0,
                    y: 0,
                    z: 0
                });
            }
        },

        pictureSP() {

            vImg.w = website.winH * vImg.ratio;
            vImg.h = website.winH + (vImg.w - website.winW);

            vImg.target.style.width = vImg.w + 'px';
            vImg.wrap.style.height = vImg.h + 'px';

            if (Math.abs(scroll.targetY) >= vImg.wrapRect.top) {
                TweenLite.set(vImg.target, {
                    width: vImg.w,
                    x: vImg.wrapRect.top - Math.abs(scroll.targetY),
                    y: 0,
                    z: 0
                });

                if (Math.abs(vImg.wrapRect.top - Math.abs(scroll.targetY)) >= vImg.w - website.winW) {
                    TweenLite.set(vImg.target, {
                        width: vImg.w,
                        x: website.winW - vImg.w,
                        y: 0,
                        z: 0
                    });
                }

                if (Math.abs(scroll.targetY) >= website.winH * 7) {
                    vImg.target.style.display = 'none';
                } else {
                    vImg.target.style.display = 'block';
                }

            } else {

                if (Math.abs(scroll.targetY) > website.winH / 2) {
                    vImg.target.style.display = 'block';
                } else {
                    vImg.target.style.display = 'none';
                }


                TweenLite.set(vImg.target, {
                    width: vImg.w,
                    x: 0,
                    y: 0,
                    z: 0
                });

            }
        },

        vImgParallax() {
            let x = Math.abs(scroll.currentY) - vImg.wrapRect.top;

            TweenLite.set(vImg.layer2, {
                x: x * 0.03,
                y: 0,
                z: 0
            });

            TweenLite.set(vImg.layer5, {
                x: (x * 0.01),
                y: 0,
                z: 0
            });

            TweenLite.set(vImg.layer6, {
                x: -(x * 0.08),
                y: 0,
                z: 0
            });


            TweenLite.set(vImg.layer7, {
                x: -(x * 0.05),
                y: 0,
                z: 0
            });

            TweenLite.set(vImg.layer9, {
                x: -(x * 0.01),
                y: 0,
                z: 0
            });

            TweenLite.set(vImg.layer10, {
                x: (x * 0.02),
                y: 0,
                z: 0
            });
        },

        updates() {
            website.animationFrame = window.requestAnimationFrame(Top.updates);
            SmoothScroll.update();
            Top.mv()
            Top.picturePC();
            Modules.parallaxFooter();
            Mouse.update();
        }
    }

    const KeepGreen = {

        init() {
            mv.l1 = document.getElementById('mv-l1');
            mv.l2 = document.getElementById('mv-l2');
            mv.l3 = document.getElementById('mv-l3');

            if (website.isDesktop) {
                KeepGreen.updates();
            }
        },

        mv() {
            TweenLite.set(mv.l1, {
                x: 0,
                y: -(scroll.currentY * 0.08),
                z: 0
            });
            TweenLite.set(mv.l2, {
                x: 0,
                y: -(scroll.currentY * 0.5),
                z: 0
            });
            TweenLite.set(mv.l3, {
                x: 0,
                y: -(scroll.currentY * 0.3),
                z: 0
            });
        },

        updates() {
            website.animationFrame = window.requestAnimationFrame(KeepGreen.updates);
            SmoothScroll.update();
            KeepGreen.mv()
            Modules.parallaxFooter();
            Mouse.update();
        }
    }

    const GreenDesign = {

        init() {

            $('#menu .flex2').removeClass('none');
            $('#menu .flex1').addClass('none');

            Filter.init();
            PanelHover.init();
            if (website.isDesktop) {
                GreenDesign.updates();
            }
        },

        updates() {
            website.animationFrame = window.requestAnimationFrame(GreenDesign.updates);
            SmoothScroll.update();
            Modules.parallaxFooter();
            Mouse.update();
        }
    }

    const GreenDetail = {

        init() {
            Filter.init();
            if (website.isDesktop) {
                FixScroll.init();
                GreenDetail.updates();
            }
        },

        updates() {
            website.animationFrame = window.requestAnimationFrame(GreenDetail.updates);
            SmoothScroll.update();
            FixScroll.update();
            Modules.parallaxFooter();
            Mouse.update();
        }
    }

    const BasicPage = {

        init() {
            if (website.isDesktop) {
                BasicPage.updates();
            }
        },

        updates() {
            website.animationFrame = window.requestAnimationFrame(BasicPage.updates);
            SmoothScroll.update();
            Modules.parallaxFooter();
            Mouse.update();
        }
    }

    const App = {

        init() {

            // $('html,body').animate({
            //     scrollTop: 0
            // }, 10);

            Mouse.init();
            Menu.init();
            Pjax.init();
            App.set();
        },

        set() {
            Modules.init();
            Modules.score();
            Resize.init();
            SmoothScroll.init();
            Anchor.init();
            Mouse.set();
            Mouse.guide();
            Modal.init();

            $('#menu .flex1').removeClass('none');
            $('#menu .flex2').addClass('none');

            if (score.top) {
                Top.init();
            } else if (score.gd) {
                GreenDesign.init();
            } else if (score.gdDetail) {
                GreenDetail.init();
            } else if (score.kp) {
                KeepGreen.init();
            } else if (score.contact) {
                BasicPage.init();
                Contactform.init();
            } else {
                BasicPage.init();
            }
        }
    }

    App.init();
    Loader.init();

})();